import firebase from "../../lib/firebase";
import axios from "axios";

/* USER SERVICE */

export const getInvitationDetails = async (invitationId: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.get(
      baseUrl + "user/fetch/invite/" + invitationId,
      {
        headers: header,
      }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const isValidInvitation = async (
  invitationId: string
): Promise<boolean> => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.get(
      baseUrl + "user/fetch/invite/" + invitationId,
      {
        headers: header,
      }
    );

    // if the invitation has already been used or is expired, or if there is none return false.
    if (
      resp.data.data?.used ||
      resp.data.data?.expires < Date.now() ||
      resp.data.data === undefined
    ) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async (token: string, userId: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: token,
    };

    const resp = await axios.get(baseUrl + "user/fetch/user/" + userId, {
      headers: header,
    });
    return resp.data;
  } catch (error) {
    throw error;
  }
};

export const isRegisteredUserById = async (userId: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.get(baseUrl + "user/fetch/exists/" + userId, {
      headers: header,
    });

    // returns true if user is registered, false if not.
    return resp.data.success;
  } catch (error) {
    throw error;
  }
};

export const isRegisteredUserByEmail = async (email: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.get(baseUrl + "user/fetch/exists/email/" + email, {
      headers: header,
    });

    // returns true if user is registered, false if not.
    return resp.data.success;
  } catch (error) {
    throw error;
  }
};

export const createNewUser = async (authUser: any) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const values = { ...authUser };

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.post(baseUrl + "user/add", values, {
      headers: header,
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (
  token: string,
  userId: string,
  userUpdate: any
) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const values = { ...userUpdate };

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: token,
    };

    const resp = await axios.post(
      baseUrl + "user/public/update/" + userId,
      values,
      {
        headers: header,
      }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const archiveInvitation = async (invitationId: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.get(
      baseUrl + "user/archive/invite/" + invitationId,
      {
        headers: header,
      }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const signUpWithEmail = async (email: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const values = { email: email };

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.post(baseUrl + "userAuth/signup", values, {
      headers: header,
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const signInWithEmail = async (email: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const values = { email: email };

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const resp = await axios.post(baseUrl + "userAuth/signin", values, {
      headers: header,
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const updateNotifications = async (
  token: string,
  uuid: string,
  email: string,
  preference: string,
  offset: number,
  currentPreference?: string,
  currentOffset?: number
) => {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

  const values = {
    preference: preference,
    offset: offset,
    currentPreference: currentPreference,
    currentOffset: currentOffset,
    email: email,
  };

  try {
    const header = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: token,
    };

    const resp = await axios.post(
      baseUrl + "user/public/notifications/update/" + uuid,
      values,
      {
        headers: header,
      }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
