import { Context, createContext, useContext, useEffect, useState } from "react";
import firebase from "./firebase";
import nookies from "nookies";

import {
  getInvitationDetails,
  isRegisteredUserById,
  isRegisteredUserByEmail,
  getUserDetails,
  createNewUser,
  archiveInvitation,
  signUpWithEmail,
  signInWithEmail,
} from "../utils/service/user";
import router from "next/router";

interface Auth {
  uid: string;
  email: string | null;
  name: string | null;
  photoUrl: string | null;
  token: string | null;
}

interface AuthContext {
  user: firebase.User | null;
  auth: Auth | null;
  organization: string;
  dbUser: [] | null;
  loading: boolean;
  error: boolean;
  signInWithEmailLink: (email: string, url: string) => Promise<void>;
  signInWithEmailLinkRequest: (email: string) => Promise<void>;
  signUpWithEmailLinkRequest: (
    invitationId: string,
    email: string
  ) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signUpWithGoogle: (invitationId: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const authContext: Context<AuthContext> = createContext<AuthContext>({
  user: null,
  auth: null,
  organization: null,
  dbUser: null,
  loading: true,
  error: false,
  signInWithEmailLink: async () => {},
  signInWithEmailLinkRequest: async () => {},
  signUpWithEmailLinkRequest: async () => {},
  signInWithGoogle: async () => {},
  signUpWithGoogle: async () => {},
  signOut: async () => {},
});

const formatAuthState = (user: firebase.User): Auth => ({
  uid: user.uid,
  email: user.email,
  name: user.displayName,
  photoUrl: user.photoURL,
  token: null,
});

function useProvideAuth() {
  const [auth, setAuth] = useState<Auth | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [provider, setProvider] = useState<string>("");
  const [user, setUser] = useState<firebase.User | null>(null);
  const [dbUser, setDbUser] = useState<[]>(null);
  const [organization, setOrganization] = useState<string>("");

  // get the token of the user and storing in cookie.
  useEffect(() => {
    return firebase.auth().onIdTokenChanged(async (user) => {
      if (!user) {
        setUser(null);
        nookies.set(undefined, "token", "", { path: "/" });
      } else {
        const token = await user.getIdToken();
        setUser(user);
        nookies.set(undefined, "token", token, { path: "/" });
      }
    });
  }, []);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const token = await user.getIdToken();
        setUser(user);
        nookies.set(undefined, "token", token, { path: "/" });
      }
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  const handleAuthChange = async (authState: firebase.User | null) => {
    if (!authState) {
      setLoading(false);
      return;
    }
    const formattedAuth = formatAuthState(authState);
    formattedAuth.token = await authState.getIdToken();
    setAuth(formattedAuth);
    const user = await getUserDetails(formattedAuth.token, formattedAuth.uid)
      .then((user) => {
        setOrganization(user.data.organization);
        setDbUser(user.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // user doesn't exist - or other problem. Best to sign out.
        signOut().then(() => {
          router.push("/");
        });
      });
  };

  const signedIn = async (
    response: firebase.auth.UserCredential,
    type: {
      signup: boolean;
      invitationId?: string;
      organization?: string;
      provider: string;
    }
  ) => {
    if (!response.user) {
      throw new Error("No User");
    } else {
      const isRegistered = await isRegisteredUserById(response.user.uid);
      if (type.signup) {
        if (isRegistered) {
          // already registererd user cannot sign  up.
          signOut();
          throw new Error("Already a registered user, try signin in.");
        } else {
          // new user can sign up.
          const authUser = formatAuthState(response.user);
          const invitationId = type.invitationId;
          const organization = type.organization;
          const provider = type.provider;

          await createNewUser({
            ...authUser,
            provider,
            organization,
            invitationId,
          }).then(() => archiveInvitation(invitationId));
        }
      } else {
        if (!isRegistered) {
          // unregistered user cannot sign in.
          signOut().then(() => {
            throw new Error("User not registered.");
          });
        } else {
          // user can sign in.d
          console.log(response);
          const authUser = formatAuthState(response.user);
          if (authUser.token) {
            const user = await getUserDetails(authUser.token, authUser.uid);
            setOrganization(user.data.organization);
            setDbUser(user.data);
          }
        }
      }

      const authUser = formatAuthState(response.user);
    }
  };

  const clear = () => {
    setAuth(null);
    setProvider("");
    setOrganization(null);
    setDbUser(null);
    setLoading(true);
  };

  const signInWithEmailLink = async (email: string, url: string) => {
    clear();
    setLoading(true);
    setProvider("email");

    return firebase
      .auth()
      .signInWithEmailLink(email, url)
      .then(
        (auth) => {
          signedIn(auth, { signup: false, provider: "email" });
        },
        (error) => {
          console.log(`Authentication ${error}`);
          setLoading(false);
          setError(true);
        }
      );
  };

  const signInWithEmailLinkRequest = async (email: string) => {
    clear();
    setLoading(true);
    setProvider("email");

    if (await isRegisteredUserByEmail(email)) {
      await signInWithEmail(email).then(async (resp) => {
        if (resp.data.success) {
          return console.log(`Sign in email sent`), setLoading(false);
        } else {
          return console.log(`Sign in Error`), setLoading(false);
        }
      });
    } else {
      return console.log(`User doesn't exist`), setLoading(false);
    }
  };

  const signUpWithEmailLinkRequest = async (
    invitationId: string,
    email: string
  ) => {
    clear();
    setLoading(true);
    setProvider("email");

    await getInvitationDetails(invitationId).then(async (resp) => {
      if (resp.data.success) {
        const organization = resp.data.data.organization;
        await signUpWithEmail(email).then(async (resp) => {
          if (resp.data.success) {
            const authUser = {
              uid: resp.data.data,
              email: email,
              name: "",
              photoUrl: "",
            };

            console.log("authUser: ", authUser);

            await createNewUser({
              ...authUser,
              provider,
              organization,
              invitationId,
            }).then(() => {
              archiveInvitation(invitationId);
            });
            return console.log(`Signed up`), setLoading(false);
          } else {
            return console.log(`Sign up error`), setLoading(false);
          }
        });
      } else {
        return console.log(`Invitation Error`), setLoading(false);
      }
    });
  };

  const signInWithGoogle = async () => {
    clear();
    setLoading(true);
    setProvider("google");

    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(
        (auth) => signedIn(auth, { signup: false, provider: "google" }),
        (error) => {
          console.log(`Authentication ${error}`);
          setLoading(false);
          setError(true);
        }
      );
  };

  const signUpWithGoogle = async (invitationId: string) => {
    clear();
    setLoading(true);
    setProvider("google");

    await getInvitationDetails(invitationId).then(async (resp) => {
      if (resp.data.success) {
        firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then(
            (auth) =>
              signedIn(auth, {
                signup: true,
                invitationId: invitationId,
                organization: resp.data.data.organization,
                provider: "google",
              }),
            (error) => {
              console.log(`Authentication ${error}`);
              setLoading(false);
              setError(true);
            }
          );
      } else {
        return console.log(`Invitation Error`), setLoading(false);
      }
    });
  };

  const signOut = async () => {
    return firebase.auth().signOut().then(clear);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(handleAuthChange);
    return () => unsubscribe();
  }, []);

  return {
    user,
    auth,
    organization,
    dbUser,
    loading,
    error,
    signInWithGoogle,
    signInWithEmailLink,
    signUpWithGoogle,
    signInWithEmailLinkRequest,
    signUpWithEmailLinkRequest,
    signOut,
  };
}

export function AuthProvider({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);
